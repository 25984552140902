<template>
    <v-container grid-list-md pa-0 relative>
      <v-layout row wrap>
        <v-flex xs12 sm12>
          <v-card class="primary--border" outlined>
            <v-card-title class="title">
              Program
              <v-spacer></v-spacer>
              <add-button
                permission="grade-create"
                @action="
                  (dialog = true,editId = null)
                "
                >New Program
              </add-button>
            </v-card-title>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="data"
              :server-items-length="data.length"
              :loading="isLoading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td class="text-left text-bold">
                    <strong>{{ item.name }}</strong>
                  </td>
                  <td class="text-center">
                    <v-chip
                      small
                      label
                      outlined
                      v-if="item.status === 1"
                      color="success"
                    >
                      Active</v-chip
                    >
                    <v-chip
                      small
                      label
                      outlined
                      v-if="item.status === 0"
                      color="error"
                    >
                      Inactive</v-chip
                    >
                  </td>
                  <td
                    class="text-right"
                  >
  
                    <edit-button
                      v-if="item.allowed"
                      permission="grade-update"
                      @agree="editProgram(item)"                   
                    />
                    <delete-button
                      v-if="item.allowed"
                      :permission="true"
                      @agree="deleteProgram(item.id)"
                    />
                  </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for found no results.
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-dialog v-model="dialog" persistent max-width="400px">
          <v-card>
            <v-card-title class="primary white--text">
              <span class="title">Add/Update</span>
            </v-card-title>
            <v-card-text class="pb-1 pt-4">
              <v-form
                ref="form"
                v-model="valid"
                :lazy-validation="lazy"
                @submit.prevent="save"
                @keyup.enter="save"
              >
                <v-select
                  id="level_filter"
                  label="Level List"
                  item-text="title"
                  item-value="value"
                  class="pa-0 level_filter"
                  outlined
                  dense
                  v-model="selectedLevel" 
                  :items="levels"
                  :loading="levelLoading"
                  :disabled="true"
                />
  
                <v-text-field
                  autocomplete="off"
                  label="Name*"
                  required
                  class="pa-0"
                  outlined
                  dense
                  v-model="programRecord.name"
                  name="name"
                  :rules="[(v) => !!v || 'Name is required']"
                />
  
                <v-select
                  :items="statuses"
                  required
                  class="pa-0"
                  label="Status*"
                  outlined
                  dense
                  v-model="programRecord.status"
                  :rules="[(v) => !!v || 'Status is required']"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="(dialog = false)"
                >Close</v-btn
              >
              <v-btn
                :loading="saving"
                color="success"
                text
                @click="validate"
                >{{ editId === null ? 'Save' : 'Update' }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </template>
  <script>
  import Mixins from "@/library/Mixins";
  
  export default {
    mixins: [Mixins],
    data: () => ({
      saving: false,
      valid: true,
      lazy: false,
      isLoading: false,
      //. Data stored here. Data information
      programRecord:{
        name:"",
        status:"1",
        type:"program",
        sub_id:"",     
      },
      data: [],
      dialog: false,
      editId: null,
      headers: [
        { text: "#", align: "left", value: "id", width: 50, sortable: false },
        { text: "Name", align: "left", value: "name", sortable: false },
        { text: "Status", align: "center", value: "rank", width: 110 },
        { text: "Action", align: "right", sortable: false, width: 200 },
      ],
      statuses: [
        {
          text: "Active",
          value: "1",
        },
        {
          text: "Inactive",
          value: "0",
        },
      ],
    levelLoading: false,
      levels: [],
      selectedLevel: null,
    }),
    mounted() {
      this.selectedLevel = this.$route.params.levelId
        ? parseInt(this.$route.params.levelId, 10)
        : "";
      this.fetchLevel();
      this.get();
    },
  
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.save();
        }
      },
  
      
      fetchLevel() {
        this.levelLoading = true;
        this.$rest
          .get("/api/level-program?type=level")
          .then(({ data }) => {
            this.levels = data.data.map((res) => {
              return { title: res.name, value: res.id };
            });
          })
          .finally(() => {
            this.levelLoading = false;
          });
      },
  
  
      /**
       * Fetch the list of faculties to display on data table
       */
      get() {
            const extraParams = "?type=program&sub_id=" + this.selectedLevel;
        this.isLoading = true;
        this.$rest.get('/api/level-program' + extraParams).then((res) => {
          this.data = res.data.data;
        }).finally(() => {
          this.isLoading = false;
        })
      },
  
      save() {
            this.programRecord.sub_id = this.selectedLevel;
        this.saving = true
  
        if (this.editId === null) {
          this.$rest.post("/api/level-program", this.programRecord).then(() => {
            this.$events.fire('notification', { message: 'Program was created successfully', status: 'success' });
            this.resetAfterSaveUpdate();
          }).finally(() => {
            this.saving = false;
          })
        } else {
          this.$rest.put("/api/level-program/" + this.editId, this.programRecord).then(() => {
            this.$events.fire('notification', { message: 'Program was edited successfully', status: 'success' });
            this.resetAfterSaveUpdate();
          }).finally(() => {
            this.saving = false;
          }) 
        }
      },
  
      resetAfterSaveUpdate() {
        this.get();
        this.saving = false;
        this.dialog = false;
        this.programRecord.name = "";
        this.programRecord.status = "1";
      },
  
      deleteProgram(id) {
        this.$rest.delete('/api/level-program/' + id).then((res) => {
          this.get();
          this.$events.fire('notification', { message: 'Program was deleted.', status: 'success' });
        }) 
      },
  
      editProgram(item) {
        this.programRecord.name = item.name;
        this.programRecord.status = "1";
        this.dialog = true;
        this.editId = item.id;
      }

      
    },
  };
  </script>
  